// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-download-device-js": () => import("./../../../src/pages/en/download/device.js" /* webpackChunkName: "component---src-pages-en-download-device-js" */),
  "component---src-pages-en-download-devices-js": () => import("./../../../src/pages/en/download/devices.js" /* webpackChunkName: "component---src-pages-en-download-devices-js" */),
  "component---src-pages-en-download-js": () => import("./../../../src/pages/en/download.js" /* webpackChunkName: "component---src-pages-en-download-js" */),
  "component---src-pages-en-download-parameter-js": () => import("./../../../src/pages/en/download/parameter.js" /* webpackChunkName: "component---src-pages-en-download-parameter-js" */),
  "component---src-pages-en-download-year-js": () => import("./../../../src/pages/en/download/year.js" /* webpackChunkName: "component---src-pages-en-download-year-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sc-download-device-js": () => import("./../../../src/pages/sc/download/device.js" /* webpackChunkName: "component---src-pages-sc-download-device-js" */),
  "component---src-pages-sc-download-devices-js": () => import("./../../../src/pages/sc/download/devices.js" /* webpackChunkName: "component---src-pages-sc-download-devices-js" */),
  "component---src-pages-sc-download-js": () => import("./../../../src/pages/sc/download.js" /* webpackChunkName: "component---src-pages-sc-download-js" */),
  "component---src-pages-sc-download-parameter-js": () => import("./../../../src/pages/sc/download/parameter.js" /* webpackChunkName: "component---src-pages-sc-download-parameter-js" */),
  "component---src-pages-sc-download-year-js": () => import("./../../../src/pages/sc/download/year.js" /* webpackChunkName: "component---src-pages-sc-download-year-js" */),
  "component---src-pages-sc-index-js": () => import("./../../../src/pages/sc/index.js" /* webpackChunkName: "component---src-pages-sc-index-js" */),
  "component---src-pages-tc-download-device-js": () => import("./../../../src/pages/tc/download/device.js" /* webpackChunkName: "component---src-pages-tc-download-device-js" */),
  "component---src-pages-tc-download-devices-js": () => import("./../../../src/pages/tc/download/devices.js" /* webpackChunkName: "component---src-pages-tc-download-devices-js" */),
  "component---src-pages-tc-download-js": () => import("./../../../src/pages/tc/download.js" /* webpackChunkName: "component---src-pages-tc-download-js" */),
  "component---src-pages-tc-download-parameter-js": () => import("./../../../src/pages/tc/download/parameter.js" /* webpackChunkName: "component---src-pages-tc-download-parameter-js" */),
  "component---src-pages-tc-download-year-js": () => import("./../../../src/pages/tc/download/year.js" /* webpackChunkName: "component---src-pages-tc-download-year-js" */),
  "component---src-pages-tc-index-js": () => import("./../../../src/pages/tc/index.js" /* webpackChunkName: "component---src-pages-tc-index-js" */)
}

